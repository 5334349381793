const farms = [
  {
    name: "Walapane Farm",
    image: require("./walapane.jpg").default,
    location: "Nuwara Eliya",
    category: "Low country vegetables and fruits",
    certifications: "PGS Certified",
  },
  {
    name: "Horowpathana Farm",
    image: require("./horowpathana.jpg").default,
    location: "Anuradhapura",
    category: "Coconut, fruits, vegetables and spices",
    certifications: "Sri Cert Certified",
  },
  {
    name: "Tea Estate",
    image: require("./tea-estate.webp").default,
    location: "Balangoda",
    category: "Low country vegetables and fruits",
    certifications: "Control Union Certified",
  },
]

export default farms
