import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import farms from "../../data/farms"

const OurFarmsPage = () => {
  return (
    <>
      <Seo title="Our Farms" />
      <Breadcrumbs title={"Our Farms"} />

      <div className="container py-5">
        {farms.map((farm, idx) => {
          return (
            <div className="row mt-5" key={idx}>
              <div className="col-sm-6 col-lg-7 d-flex align-items-center">
                <div className={styles.farm_image}>
                  <img src={farm.image} alt={farm.name} />
                </div>
              </div>
              <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                <div>
                  <h2>{farm.name}</h2>
                  <p>
                    Standort: {farm.location}, Sri Lanka
                    <br />
                    {farm.certifications}
                    <br />
                    {farm.category}
                  </p>
                  {/*<p>{farm.description}</p>*/}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default OurFarmsPage
